import React, { Component } from 'react';
import { Col, Container, Row } from "reactstrap";
import { deliveryTruck } from "../../../assets/images";

class Ebay extends Component {
  render() {
    return (
      <section className="ebay">
        <Container>
          <Row className="g-4">
            <Col lg={4}>
              <img src={deliveryTruck} alt="" className="d-block mx-auto" width={300} />
            </Col>
            <Col lg={8}>
              <h2 className="section-title mb-3">Amazon sellers who cross list products to eBay make 30% more sales each month.</h2>
              <p>
                Amazon is doubling down on their <a href="https://supplychain.amazon.com/" target="_blank" rel="noopener noreferrer">Multi-Channel Fulfillment</a> program 
                and strategy because they want and need to thin their warehouse inventory. Your inventory, sitting in their warehouses, collecting dust 
                does them no good and doesn’t make money.
              </p>
              <p>AccelerList makes it easy to cross list Amazon inventory to your eBay account. Make the sale and we track everything for you. And it’s free with every subscription.</p>
              <a href="https://app.accelerlist.com/register" className="btn btn-primary btn-icon mt-3">
                <span>Get Started</span>
                <span className="icon icon-arrow-right icon-18"></span>
              </a>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}

export default Ebay;