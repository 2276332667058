import React, { Component } from "react";
import { logoAmazon, logoEbay, speedArrows, testimonialAuthor } from "../../../assets/images";
import './style.css';
import { Button, ButtonGroup, Container } from "reactstrap";

class Pricing extends Component {
  state = {
    selected_cycle: 'monthly'
  }

  componentDidMount = () => {
    if(window.location?.href?.indexOf("#pricing") > -1) {
      setTimeout(() => {
        this.targetRef.scrollIntoView({
          behavior: 'smooth'
        })
      }, 500)
    }
  }

  randomNumber = () => {
    const date = new Date();
    const generator = new Math.seedrandom(date.toDateString());
    const randomNumber = generator() * 20 + 12;
    return Math.floor(randomNumber);
  }

  render() {
    return (
      <section className="pricing" id="pricing" ref={ref => {this.targetRef = ref}}>
        <Container>
          <div className="section-heading">
            <h2 className="section-title">Start product listing today and make money on Amazon</h2>
            <ButtonGroup className="mt-3">
              <Button
                size="sm"
                color={this.state.selected_cycle === 'monthly' ? 'primary' : 'secondary'}
                onClick={() => this.setState({ selected_cycle: 'monthly' })}
              >Monthly</Button>
              <Button
                size="sm"
                color={this.state.selected_cycle === 'annually' ? 'primary' : 'secondary'}
                onClick={() => this.setState({ selected_cycle: 'annually' })}
              >Annually</Button>
            </ButtonGroup>
          </div>
          <div className="pricing-box">
            <div className="inner-box">
              <div className="left">
                <div className="content left-top">
                  <div className="main-price">
                    {this.state.selected_cycle === 'annually' ?
                    <React.Fragment>
                      <span className="number">$399.88</span><span className="period">/year</span>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <span className="number">$39.98</span><span className="period">/monthly</span>
                    </React.Fragment>
                    }
                  </div>
                  <div className="hstack gap-4 justify-content-center text-success">
                    <strong>FREE MOBILE APP</strong>
                    <strong>FREE EBAY LISTING</strong>
                  </div>
                </div>
                <div className="content left-bottom pt-0">
                  <ul className="benefit-list">
                    <li><span>Built in box content solution</span></li>
                    <li><span>Track Listers &amp; Sources</span></li>
                    <li><span>Create custom MSKUs</span></li>
                    <li><span>FBA &amp; Merchant Fulfilled Product Listing</span></li>
                    <li><span>Effortless Product Listing</span></li>
                    <li><span>Product Research</span></li>
                    <li><span>Free employee accounts</span></li>
                    <li><span>Free mobile scouting app</span></li>
                    <li><span>Free eBay cross platform product listing</span></li>
                  </ul>
                </div>
              </div>
              <div className="right">
                <div className="content d-flex align-items-start justify-content-center gap-5 pb-0">
                  <img src={logoAmazon} alt="Amazon logo" className="img-fluid" />
                  <img src={logoEbay} alt="eBay logo" className="img-fluid" />
                </div>
                <div className="content text-center">
                  <div className="content-repricing">
                    <h3 className="title">Reprice your products and stay competitive for $24/mo extra</h3>
                    <ul className="benefit-list blue smaller mt-3">
                      <li><span>Keep your products in the buy box</span></li>
                      <li><span>Earn more with our smart pricing actions</span></li>
                      <li><span>Protect againts the Race to the Bottom</span></li>
                    </ul>
                  </div>
                </div>
                <div className="content right-bottom">
                  <div className="pricing-testimonial">
                    <div className="testimonial-photo">
                      <img src={testimonialAuthor} width="80" height="80"  alt="AccelerList Testimonial" />
                    </div>
                    <div className="testimonial-text">
                      <p>
                        "I have found the support to be excellent, and the software helps me keep track of
                        my income and expenses, list items and reprice - all in one place!"
                      </p>
                      <div className="testimonial-author">
                        - <span className="name">Robert</span> • <span className="info">Hunstville, AL</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a
              href="https://app.accelerlist.com/register"
              className="pricing-box-cta"
            >
              <span
                className="btn btn-info btn-lg"
              >
                <span>Start your FREE 14 day trial</span>
              </span>
              <div className="pricing-box-cta-fomo">
                <img src={speedArrows} alt="" />
                <p>
                  <strong>Hurry:</strong> {this.randomNumber()} sellers signed up today.
                  <br/>Act now and start making $$$ on Amazon and eBay!
                </p>
              </div>
            </a>
          </div>
        </Container>
      </section>
    )
  }
}

export default Pricing;
